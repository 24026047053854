@charset "UTF-8";
.layout-boxed {
  background: url("../img/boxed-bg.jpg") repeat fixed; }

.login-page .container {
  margin-top: 77px; }

.login-box {
  width: 500px; }

.dropdown-menu > li > a.text-danger {
  color: #dd4b39; }

.content {
  padding-bottom: 150px; }

.content-header {
  margin-bottom: 20px; }

table td.contextmenu {
  width: 40px; }

.cursor-pointer:hover {
  cursor: pointer; }

.alert ul {
  padding-left: 20px; }

.scroll-to-top {
  display: none;
  border-radius: 3px;
  background: #aaa;
  position: fixed;
  bottom: 40px;
  right: 20px;
  padding: 1em; }

input:required, select:required, textarea:required {
  border-left: 1px dashed red; }

/* Font Awesome */
i.fa-ico-left {
  margin-right: 3px; }

table tr.tr-weekend td {
  background-color: #ddd; }

tr.highlight td {
  background-color: #dff0d8 !important; }

/* Groups */
.group_roles_permissions_table ul {
  padding-left: 20px; }

/* Schultage */
table.schultage-table tr td a {
  color: black; }

td.schultag-yes {
  background-color: green; }

td.schultag-weekend {
  background-color: #ccc; }

table.schultage-table tr td.schultag-feiertag, table.schultage-table tr td.schultag-feiertag a {
  color: #dd4b39;
  font-weight: bold; }

/* Sortierungspfeile näher an Beschreibung */
table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after {
  position: static;
  top: 8px;
  right: 8px;
  display: inline;
  font-family: Glyphicons Halflings;
  opacity: .5;
  margin-left: 5px; }

.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a {
  white-space: normal; }

textarea {
  filter: alpha(opacity=80);
  border-left: #346789 1px solid; }

/*# sourceMappingURL=app.css.map */
